@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}


*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--page-padding: 1.5rem;
	--color-text: #000;
	--color-text-light: #FFF;
	--color-bg: #fff;
	--color-link: rgba(255, 255, 255, 0.6);
	--color-link-hover: #888;
	--color-bg-1: #FFFFFF;
	--color-bg-2: #0092C3;
	--color-bg-3: #eaeaea;
	--color-bg-4: var(--color-bg-2);
	--color-bg-5: var(--color-bg-1);
	--color-bg-6: var(--color-bg-2);
	--color1: #f94144;
	--color2: #f3722c;
	--color3: #f8961e;
	--color4: #f9c74f;
	--color5: #90be6d;
	--color6: #43aa8b;
	--color7: #577590;
	--color8: #277da1;
	--color9: #f9844a;
	--color10: #f94144;
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: "Lato", sans-serif;
	font-variation-settings: "slnt" 0, "wdth" 150, "wght" 500, "INKT" 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	overflow-x: hidden;
}

.demo-2 {
	--color-text: #000;
	--color-bg: #e1e1e1;
	--color-link: rgba(0, 0, 0, 0.6);
	--color-link-hover: #000;
	--color-bg-1: #9d9d9d;
	--color-bg-2: #c7c7c7;
	--color-bg-3: var(--color-bg-1);
	--color-bg-4: var(--color-bg-2);
	--color-bg-5: var(--color-bg-1);
	--color-bg-6: var(--color-bg-2);
}

.text--white {
	color: var(--color-text-light)
}

.text--center {
	text-align: center;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 5000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: var(--page-padding);
	position: relative;
	display: grid;
	z-index: 1000;
	width: 100%;
	grid-row-gap: 1rem;
	grid-column-gap: 2rem;
	justify-items: start;
	text-transform: uppercase;
	font-size: 12px;
}

.frame--header {
	height: 100%;
	z-index: 3000;
	background-size: auto 80%;
	background-position: 120% 50%;
	background-repeat: no-repeat;
	grid-template-areas: 'title' 'prev' 'back' 'sub' 'sponsor' 'demos' 'heading';
}

.frame--footer {
	grid-template-areas: 'credits' 'author';
	align-content: end;
}

.frame #cdawrap {
	justify-self: start;
}

.frame a {
	pointer-events: auto;
}

.frame__title {
	grid-area: title;
	font-size: inherit;
	margin: 0;
}

.frame__back {
	grid-area: back;
	justify-self: start;
}

.frame__prev {
	grid-area: prev;
	justify-self: start;
}

.frame__sub {
	grid-area: sub;
}

.frame__demos {
	grid-area: demos;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	align-self: start;
	flex-wrap: wrap;
	background: var(--color-bg);
	border: 1px solid;
	padding: 1rem;
	border-radius: 10px;
}

.frame__demos>* {
	width: 2rem;
	display: block;
	flex: none;
	text-decoration: none;
	border-radius: 50%;
	aspect-ratio: 1;
	border: 1px solid var(--color-link-hover);
	display: grid;
	place-items: center;
	padding-top: 0.2em;
	background: var(--color-bg);
}

.frame__demos>span {
	filter: invert(100%);
}

.frame__demos::before {
	content: "Variations:";
}

.frame__heading {
	grid-area: heading;
	align-self: center;
	justify-self: start;
	width: min-content;
	display: flex;
	flex-direction: column;
	margin-top: 10vh;
}

.frame__heading h2 {
	margin: 0;
	line-height: 0.9;
	text-transform: uppercase;
	margin-left: -0.065em;
	font-size: clamp(2.5rem, 12vw, 7rem);
	letter-spacing: -0.105em;
	font-variation-settings: "slnt" 0, "wdth" 400, "wght" 900, "INKT" 400;
}

i {
	font-style: normal;
	font-variation-settings: "slnt" 0, "wdth" 100, "wght" 400, "INKT" 400;
}

.frame__heading p {
	margin: 0;
}

.frame__credits {
	grid-area: credits;
}

.frame__author {
	display: flex;
	gap: 1.5rem;
	grid-area: author;
}

.content {
	padding: var(--page-padding);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content--perspective {
	perspective-origin: 50% 0%;
	perspective: 1000px;
}

.text-large {
	font-size: clamp(1.5rem, 5vw, 3rem);
	margin: 0 0 0.85em 0;
	line-height: 1.2;
}

.text-medium {
	font-size: 24px;
	margin: 0.85em 0 0.85em 0;
	line-height: 1.5;
}

.text-small {
	font-size: 18px;
}

.content--sticky {
	width: 100vw;
	position: sticky;
	top: 0;
	--offset: 0px;
	top: var(--offset);
	height: calc(100vh - var(--offset));
}

.content--grid,
.content--grid .content__inner {
	display: flex;
	overflow: hidden;
	grid-column-gap: 5vw;
	grid-row-gap: 2vh;
	align-content: center;
	/* grid-template-areas: 'content-img' 'content-title' 'content-text'; */
	justify-items: center;
}

.content--grid:has(.content__inner) {
	display: block;
	padding: 0;
}

.inner--container {
	width: 80vw;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
}

.column {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.center {
	align-items: center;
	justify-content: center;
}

.inner--container.center * {
	text-align: center;
}

.bouce--container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;
	margin-top: 30px;
}

.bouce--inner--container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	flex-basis: 33.333%;
}

.right {
	width: 80%;
}

.left {
	width: 20%;
}

.content__inner {
	width: 100%;
	height: 100%;
	border-radius: 0 0 2rem 2rem;
}

.content--card {
	display: flex;
	flex-direction: column;
	height: 80vh;
	width: 95vw;
	max-width: 500px;
	aspect-ratio: 0.8;
	top: 10vh;
	margin: auto;
	border-radius: 14px;
	gap: 3vh;
	text-align: center;
	margin-bottom: 5vh;
}

.content--card .content__title {
	font-size: clamp(1.5rem, 4vw, 3.5rem);
}

.content--card .content__title i {
	display: block;
}

.content--half {
	display: flex;
	flex-direction: column;
	gap: 3vh;
	text-align: center;
}

.content--half:nth-child(odd) {
	margin-left: auto;
}

.content__img {
	grid-area: content-img;
	width: 50%;
	max-width: 300px;
	height: auto;
}

.content__img--large {
	width: 60%;
	height: auto;
}

.content__img--small {
	height: 35%;
	width: auto;
}

.spacer {
	margin-top: 20vh;
}

.content__title {
	grid-area: content-title;
	line-height: 1;
	font-weight: normal;
	font-size: clamp(1.7rem, 5.5vw, 4.5rem);
	margin: 0;
	font-variation-settings: "slnt" 0, "wdth" 400, "wght" 900, "INKT" 400;
}

.content__text {
	margin: 0;
	max-width: 500px;
	grid-area: content-text;
	text-align: center;
	line-height: 1.5;
	padding: 0 1rem;
	backface-visibility: hidden;
}

.content__text--narrow {
	max-width: 300px;
}

.content--intro {
	width: 100%;
	height: 80vh;
	z-index: 2;
	background-image: url('https://plus.unsplash.com/premium_photo-1679953751182-db0479051c31?q=80&w=3732&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center right;
	position: relative;
}

.content--intro::before {
	content: '';
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	z-index: -1
}

.content--outro {
	padding-top: 50vh;
	padding-bottom: 30vh;
}

.bg-1 {
	background: var(--color-bg-1);
}

.bg-2 {
	background: var(--color-bg-2);
}

.bg-3 {
	background: var(--color-bg-3);
}

.bg-4 {
	background: var(--color-bg-4);
}

.bg-5 {
	background: var(--color-bg-5);
}

.bg-6 {
	background: var(--color-bg-6);
}




.history-wrapper-content .scrollContainer {
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.history-wrapper-content .section {
	height: 100vh;
}

.history-wrapper-content .section.start-content {
	flex: 0 0 100vw;
}

.history-wrapper-content .caption h2 {
	font-size: clamp(7rem, 6vw, 10rem);
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: -6.66667px;
}

.history-wrapper-content .caption p {
	max-width: 60rem;
	font-size: clamp(2rem, 6vw, 2rem);
}

.history-wrapper-content .double-images {
	position: relative;
	width: 90rem;
	display: flex;
}

.history-wrapper-content .double-images.type-01 {
	margin: 10rem 0;
}

.history-wrapper-content .double-images.type-01 figure:first-child {
	flex-basis: 60rem;
	flex-shrink: 0;
	height: 80vh;
}

.history-wrapper-content .double-images.type-01 figure:last-child {
	max-width: 45rem;
	height: 50vh;
	position: absolute;
	bottom: -4rem;
	right: -26rem;
	top: auto;
}

.history-wrapper-content .double-images.type-02 figure:first-child {
	flex-basis: 90rem;
	flex-shrink: 0;
	height: 100vh;
}

.history-wrapper-content .double-images.type-02 figure:last-child {
	max-width: 45rem;
	height: 50vh;
	position: absolute;
	bottom: -4rem;
	right: -26rem;
	top: auto;
}

.history-wrapper-content .triple-images {
	position: relative;
	max-width: 110rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10rem 0;
}

.history-wrapper-content .triple-images figure {
	width: 50rem;
	height: 50rem;
}

.history-wrapper-content .triple-images figure:first-child {
	width: 50vh;
	height: 50vh;
	padding-bottom: 100%;
}

.history-wrapper-content .triple-images figure:first-child img {
	width: 100%;
	height: 100%;
}

.history-wrapper-content .triple-images figure:nth-child(2) {
	width: 35vh;
	height: 35vh;
	position: absolute;
	top: -35%;
	right: -20%;
}

.history-wrapper-content .triple-images figure:nth-child(2) img {
	width: 100%;
	height: 100%;
}

.history-wrapper-content .triple-images figure:nth-child(3) {
	width: 40vh;
	height: 40rem;
	position: absolute;
	bottom: -35%;
	left: -20%;
}

.history-wrapper-content .triple-images figure:nth-child(3) img {
	width: 100%;
	height: 100%;
}

.history-wrapper-content figure {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.history-wrapper-content figure.start-image {
	width: 40%;
	height: 100%;
}

.history-wrapper-content figure.start-image img {
	width: 140%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-o-object-fit: cover;
	object-fit: cover;
}

.history-wrapper-content figure img {
	width: 140%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	-o-object-fit: cover;
	object-fit: cover;
}

.history-wrapper-content .start-chapter .caption {
	width: 60%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	padding-left: 5vw;
	margin-bottom: 10rem;
}

.history-wrapper-content .start-chapter .caption h2 {
	font-size: clamp(7rem, 6vw, 10rem);
	text-transform: uppercase;
	line-height: 1;
}

.history-wrapper-content .start-chapter .caption p {
	max-width: 60rem;
	padding-left: 10rem;
	font-size: clamp(2rem, 6vw, 2rem);
}

.history-wrapper-content .chapters {
	height: 100%;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 0 10vw;
}

.history-wrapper-content .chapters.c01 .caption {
	padding-left: 20rem;
}

.history-wrapper-content .chapters.c02 {
	padding: 0 10vw;
}

.history-wrapper-content .chapters.c02 .caption {
	padding-left: 20rem;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	margin-top: 10rem;
}

.history-wrapper-content .item {
	width: 100%;
	height: 100%;
	display: flex;
	background: #fff;
}

/* Vantaggi */
.columns-container {
	display: flex;
	width: 100%;
	height: 100vh;
}

.plus--column {
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color1);
	position: relative;
	overflow: hidden;
	transition: flex 0.3s ease;
	flex-direction: column;
	padding: 20px;
}

.plus--column:nth-child(2) {
	background-color: var(--color2);
}

.plus--column:nth-child(3) {
	background-color: var(--color3);
}

.plus--column:nth-child(4) {
	background-color: var(--color4);
}

.plus--column:nth-child(5) {
	background-color: var(--color5);
}

.plus--column:nth-child(6) {
	background-color: var(--color6);
}

.plus--column:nth-child(7) {
	background-color: var(--color7);
}

.plus--column:nth-child(8) {
	background-color: var(--color8);
}

.plus--column:nth-child(9) {
	background-color: var(--color9);
}

.plus--column:nth-child(10) {
	background-color: var(--color10);
}

.title {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	font-size: 24px;
	color: #fff;
}

.description {
	bottom: 20px;
	left: 20px;
	right: 20px;
	/* font-size: 18px; */
	color: #fff;
	opacity: 0;
	height: 0;
}

.plus--column.active .title {
	writing-mode: horizontal-tb;
	transform: rotate(0deg);
}

.plus--column.active .description {
	opacity: 1;
}


@media screen and (min-width: 63em) {
	body {
		--page-padding: 2rem 3rem;
	}

	.frame--header {
		height: 100vh;
		grid-template-columns: auto auto auto 1fr 1fr;
		grid-template-rows: auto 1fr auto;
		align-content: space-between;
		grid-template-areas: 'title back prev sub sponsor' 'heading heading heading heading heading' 'demos demos demos demos demos';
	}

	.frame__heading {
		margin-top: 0;
	}

	.frame__heading h2 {
		white-space: nowrap;
	}

	.frame__heading p {
		margin: 0 0 0 auto;
	}

	.content--grid .content__title {
		align-self: end;
	}

	.frame__demos {
		position: fixed;
		bottom: 2rem;
	}

	.frame--footer {
		padding-bottom: 0;
		grid-template-areas: 'credits ...' '... author';
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto 6rem;
	}

	.frame__author {
		align-self: center;
	}

	.frame #cdawrap {
		max-width: 300px;
		text-align: right;
	}

	.frame #cdawrap,
	.frame__author,
	.frame__sub {
		justify-self: end;
	}

	.content--grid,
	.content--grid .content__inner {
		grid-template-areas: 'content-img content-title' 'content-img content-text';
		grid-template-columns: 30% 1fr;
		justify-items: start;
	}

	.content__img--large {
		width: 160%;
	}

	.content__img--left {
		justify-self: end;
	}

	.content__text--left {
		text-align: left;
		align-self: start;
	}

	.content--card {
		width: 50vw;
	}

	.content--half {
		width: 50%;
	}
}

@media screen and (max-width: 768px) {
	.text-medium {
		font-size: 21px;
	}
}

@media screen and (max-width: 680px) {
	:root {
		--page-padding: 20px;
	}

	.columns-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.plus--column {
		background-color: var(--color1);
		overflow: hidden;
		height: 30px;
		transition: height 0.5s ease;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		position: relative;
	}

	.text-large {
		font-size: clamp(1.5rem, 5vw, 3rem);
		margin: 0 0 0.85em 0;
		line-height: 1.2;
		font-variation-settings: "slnt" 0, "wdth" 100, "wght" 500, "INKT" 100;
	}

	.text-medium {
		font-size: 17px;
	}

	.title {
		writing-mode: horizontal-tb;
		transform: rotate(0deg);
		font-size: 21px;
		color: #fff;
		margin: 0;
	}

	.inner--container {
		width: 100%;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
	}

	.bouce--inner--container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 5px;
		flex-basis: 100%;
	}

	.inner--container {
		align-items: flex-start;
	}

	.sm--no--flex {
		display: block;
	}

	.bouce--inner--container {
		display: block;
	}

	.inner--container p {
		margin: 4px 0;
	}

	.inner--container h4 {
		margin: 7px 0;
	}

	.content--sticky {
		height: calc(100vh - var(--offset));
		/* justify-content: start; */
	}
}